<template>
  <b-field v-if="siteId" label="Site controls">
    <b-select
      v-model="selected"
      :loading="processing"
      :disabled="processing"
      placeholder="Select site action"
      expanded
    >
      <optgroup label="Site controls:">
        <option v-if="site.isDeleted" :value="[restoreSite]"
          >Restore site</option
        >
        <option :value="[viewSiteNotes]">View site notes</option>
        <option :value="[viewSiteSecrets]">View site secrets</option>
        <option :value="[viewLogs, { siteId }]">View site logs</option>
        <option :value="[viewSiteAsAdmin]">View site (as admin)</option>
        <option :value="[viewSiteAsClient]">View site (as client)</option>
        <option v-if="!site.isDeleted" :value="[addTask]">Add new task</option>
        <option v-if="!site.isDeleted" :value="[runMalwareScan]"
          >Run malware scan</option
        >
        <option
          v-if="!site.isSubscribed && !site.isDeleted"
          :value="[upgradeSubscription]"
          >Subscribe</option
        >
        <option v-if="!site.isDeleted" :value="[createManualInvoice]"
          >Create manual invoice</option
        >
        <option v-if="!site.isDeleted" :value="[createCustomContract]"
          >Create custom contract</option
        >
      </optgroup>
      <optgroup v-if="site.hostingData" label="cPanel:">
        <option
          v-for="(hosting, index) in site.hostingData"
          :key="index"
          :value="[resetPassword, hosting.id]"
          ><span>Reset cPanel password</span>
          <span v-if="site.hostingData.length > 1">
            for {{ hosting.hostname }}</span
          >
        </option>
      </optgroup>
    </b-select>
  </b-field>
</template>

<script>
export default {
  name: "StaffSiteControls",
  props: {
    siteId: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      processing: false,
      selected: null
    };
  },
  computed: {
    site() {
      return this.$store.getters["sites/site"](this.siteId) || {};
    },
    hasTaskCredits() {
      return this.$store.getters["sites/site/hasTaskCredits"](this.siteId);
    }
  },
  watch: {
    async selected(action) {
      if (action === null) return;
      this.processing = true;
      if (this.$_.isFunction(action[0])) await action[0](action[1]);
      this.$nextTick(() => {
        this.selected = null;
        this.processing = false;
      });
    }
  },
  async created() {
    await this.$store.dispatch("sites/setHostingInfo", { siteId: this.siteId });
  },
  methods: {
    resetPassword(hostingId) {
      return this.$store
        .dispatch("sites/resetcPanelPassword", {
          siteId: this.siteId,
          hostingId
        })
        .then(() => {
          this.$toast.open({
            message: `New password added to site secrets`
          });
        })
        .catch(() => {
          this.$toast.open({
            message: "Error resetting password",
            type: "is-danger"
          });
        });
    },
    runMalwareScan() {
      window.open(
        `https://backupstatus.fixed.net/malware.php?siteid=${this.siteId}`,
        "_blank"
      );
    },
    restoreSite() {
      this.$store
        .dispatch("sites/restoreSite", { siteId: this.siteId })
        .then(result => {
          this.$toast.open({
            message: result.message
          });
        })
        .catch(error => {
          this.$toast.open({
            message: `${error.message}`,
            type: "is-danger"
          });
        });
    },
    viewLogs(params) {
      this.$modal.open({
        component: () => import("@shared/logs/_logsModal"),
        parent: this,
        width: 720,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: this.$_.merge({}, { adminContext: true }, params)
      });
    },
    viewSiteSecrets() {
      this.$modal.open({
        component: () => import("@shared/sites/_siteSecretsModal"),
        parent: this,
        width: 720,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          siteId: this.siteId
        }
      });
    },
    viewSiteNotes() {
      this.$modal.open({
        component: () => import("@shared/sites/_siteNotesModal"),
        parent: this,
        width: 720,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          siteId: this.siteId
        }
      });
    },
    viewSiteAsAdmin() {
      this.$router.push({ path: `/sites/magic/${this.siteId}` });
    },
    viewSiteAsClient() {
      this.$router.push({ path: `/sites/${this.siteId}` });
    },
    addTask() {
      this.$store.dispatch("tasks/openAddTaskModal", {
        siteId: this.siteId
      });
    },
    upgradeSubscription() {
      this.$modal.open({
        component: () => import("@shared/contracts/_selectPlanModal"),
        parent: this,
        width: 720,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          siteId: this.siteId
        }
      });
    },
    createCustomContract() {
      this.$modal.open({
        component: () => import("@shared/contracts/_createCustomContract"),
        parent: this,
        width: 720,
        hasModalCard: true,
        canCancel: [],
        props: {
          siteId: this.siteId
        }
      });
    },
    createManualInvoice() {
      this.$modal.open({
        component: () => import("@shared/invoices/_createManualInvoiceModal"),
        parent: this,
        width: 720,
        hasModalCard: true,
        canCancel: [],
        props: {
          userId: this.$_.get(this.site, "owners[0]"),
          siteId: this.siteId
        }
      });
    }
  }
};
</script>
